<template>
  <div class="accordion-wrapper">
    <button
      @click="toggleAccordion()"
      class="accordion-toggle"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${_uid}`"
    >

      <slot name="title" />

      <i :class="isOpen ? 'fas fa-caret-up' : 'fas fa-caret-down'" class="icon-large"></i>
    </button>

    <div class="content" v-show="isOpen" :id="`collapse${_uid}`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>

<style scoped lang="scss">
  button {
    cursor: pointer;
  }

  .accordion-toggle {
    background: none;
    text-align: left;
    width: 100%;
    border: 0;
    color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-16);
    padding-left: 0;
    gap: var(--space-16);
  }

  .accordion-wrapper {
    border-bottom: var(--borderW) solid var(--white);
  }


</style>
