import { createStore } from 'vuex'
// https://fjolt.com/article/vue-vuex-getting-started#how-to-save-vuex-data-to-local-storage

export default createStore({
  state: {
    // save player data
    playerArray: [],
    // score sorted array
    sortedPlayerArray: [],
    // save gameLength data
    gameLength: [],
    // used to show in UI
    // everytime the counter process has been completed it gets incremented
    roundCounter: 1,
    // index to loop through the players in the calculator
    // ensures that after a reload or exit it starts with the correct player
    calcIndex: 0,
    // bool to control the behaviour of the settings
    // cancel and btn text are different if a game is running
    runningGame: Boolean,
    // check if user has accepted cookies
    hasCookiesAccepted: Boolean
  },

  getters: {
    // get player array length
    playerArLength (state) {
      return state.playerArray.length
    },

    getGameLength(state) {
      return state.gameLength[0].points
    },
  },
  mutations: {
    // add player to playerArray
    addPlayer(state, newPlayer) {
          state.playerArray.push({
                name: newPlayer.name,
                score: newPlayer.score,
                isEditable: false,
                calculated: false
            })
            // invoke function to sava data to LS
            this.commit("playerToLS");
        },

    // add sorted ranks to sortedPlayerArray
    addSorted (state, sortedPlayer) {
      state.sortedPlayerArray = sortedPlayer;
        // invoke function to sava data to LS
        this.commit("playerToLS");
    },

    removePlayer(state, index) {
        state.playerArray.splice(index, 1)
        // invoke function to sava data to LS
        this.commit("playerToLS");
    },

    // make input field editable to change the playername
  editPlayer(state, index) {
        state.playerArray[index].isEditable = !state.playerArray[index].isEditable;

        // invoke function to sava data to LS
        this.commit("playerToLS");
    },

    // save score to player array
    addScore(state, saveResult) {
      state.playerArray[saveResult.index].score += saveResult.score
      state.playerArray[saveResult.index].calculated = true
      // invoke function to sava data to LS
      this.commit("playerToLS");
    },

    // change bool to true if a game has started
    setGameActive(state){
      state.runningGame = true;
      // invoke function to sava data to LS
      this.commit("playerToLS");
    },

    setGameInactive(state) {
      state.runningGame = false;
      // invoke function to sava data to LS
      this.commit("playerToLS");
    },

    // reset the calculated bool
    resetCalculated(state){
      for(let i = 0; i < state.playerArray.length; i++) {
        state.playerArray[i].calculated = false;
      }
    },

    // add gameLength to state
    addGameLength(state, gameLength) {
      state.gameLength = [] // reset array
      state.gameLength.push({
        length: gameLength.length,
        points: gameLength.points,
        time: gameLength.time,
        index: gameLength.index
      })
  	    this.commit("playerToLS");
      },

      // increment round counter after the last player has been calculated
      incrementRound(state){
        state.roundCounter++;
        this.commit("playerToLS");
      },

      // increment calcIndex after calculation of one player has been finished
      incrementCalcIndex(state) {
        state.calcIndex++;
        this.commit("playerToLS");
      },

      // reset calculator index after the last player has been calculated
      resetCalcIndex(state) {
        state.calcIndex = 0;
        this.commit("playerToLS");
      },

      // resetRound counter for a new game
      resetRoundCounter(state) {
        state.roundCounter = 1;
        this.commit("playerToLS");
      },

      // resets if a new game will be played
      resetPlayerArray(state) {
        state.playerArray = []
        // invoke function to sava data to LS
        this.commit("playerToLS");
      },

      resetPlayerScore(state) {
        for(let i = 0; i < state.playerArray.length; i++) {
          state.playerArray[i].score = 0;
        }
      },

      resetSortedPlayerArray(state) {
        state.sortedPlayerArray = []
        // invoke function to sava data to LS
        this.commit("playerToLS");
      },

      resetGameLength(state) {
        state.gameLength = [] // reset array
    	  this.commit("playerToLS");
      },

      // addConsent(state) {
      //   state.hasCookiesAccepted = true;
      //   localStorage.setItem('cookie-consent', JSON.stringify(state.hasCookiesAccepted))
      // },

      // save store to localstorage
      playerToLS(state){
        localStorage.setItem('store', JSON.stringify(state));
      },

    // load store from local storage if it exists
    // replaces the complete store
    loadStore() {
      if(localStorage.getItem('store')) {
          try {
              this.replaceState(JSON.parse(localStorage.getItem('store')));
          }
          catch(e) {
              console.log('Could not initialize store', e);
          }
      }
}

  },
  actions: {
  },
  modules: {
  }
})
