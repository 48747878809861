<template>
  <button @click="onClick()" :class="btnClass" class="btn">
    <i v-if="icon" :class="icon" class="icon-marginR-8"></i> {{ text }}
  </button>
</template>

<script>
export default {
  name: 'customButton',

  props: {
        text: String,
        icon: {
          type: String,
          default: ''
        },
        btnClass: String
      },

  methods: {
      onClick() {
        // console.log('Click');
        this.$emit('btn-click');
      }
    }

}
</script>

<style lang="scss" scoped>
.btn {
  padding: var(--space-16);
  font-size: var(--fsize-copy);
  font-weight: var(--font-semibold);
  background: var(--white);
  border: var(--borderW) solid var(--white);
  width: 100%;
  cursor: pointer;

  &:hover {
    background: var(--btn-hover);
    border-color: var(--btn-hover);
  }
  &:first-child {
    margin-top: var(--space-16);
  }
}

.btn-main {

}

.btn-secondary {
  margin-top: var(--space-12);
  background: none;
  color: var(--white);

  &:hover {
    color: var(--main-color);
  }
}

button:disabled,
button[disabled]
button:disabled:hover,
button[disabled]:hover {
  background: var(--disabled);
  border: var(--borderW) solid var(--disabled);
  cursor: not-allowed;
}
</style>
