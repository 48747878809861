<template>
<div v-if="!consentCookies" class="backdrop">
  <div class="cookie-wrapper">


    <div class="header">
      <img src="../assets/icon/frantic/the-all-seeing-eye.svg" style="margin-bottom: -8px;" alt="">
      <button
        type="button"
        class="close-button"
        @click="saveConsent"
      >
        <i class="fas fa-times icon-large"></i>
      </button>
    </div>
    <div class="content">
      <p class="semibold" style="font-size: var(--fsize-21);">
        The all-seeing eye
      </p>
      <p>
        Diese Seite nutzt Cookies um dein Nutzungserlebnis zu verbessern. Mit der Nutzung der Seite erklärst du dich mit der <router-link to="/datenschutz">Datenschutzerklärung</router-link> einverstanden.
      </p>
      <custom-button
        text="Okay"
        icon="fas fa-check"
        @btn-click="saveConsent"
      ></custom-button>
    </div>
  </div>
</div>
</template>

<script>
import customButton from '@/components/ui-elements/customButton.vue'
export default {
  name: 'cookieBanner',

  components: {
    customButton
  },

  mounted() {
    this.checkConsent();
  },

  data() {
    return {
      consentCookies: Boolean
    }
  },

  methods: {

    checkConsent() {
      let lsItem = localStorage.getItem("cookie-consent");
      // console.log(lsItem);
      // console.log(typeof lsItem);
      if (lsItem === null || JSON.parse(lsItem) === false) {
        console.log("no cookie");
        this.consentCookies= false;
      } else if (JSON.parse(lsItem) === true) {
        console.log("cookie");
        this.consentCookies= true;
      }

    },

    // save result to store after confirmation
    saveConsent() {
      this.consentCookies = true;
      localStorage.setItem('cookie-consent', JSON.stringify(this.consentCookies))
      // console.log("consent given");
      // this.$store.commit('addConsent')
      //
      // let consent = JSON.parse(localStorage.getItem("cookie-consent"));
      // console.log(consent);
      //
      // if(consent) {
      //   return true;
      // } else {
      //   return false;
      // }
  }
}
}
</script>

<style scoped lang="scss">
  img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(1443%) hue-rotate(49deg) brightness(99%) contrast(87%);
    width: 45px;
  }
  .backdrop {
    background: #fff;
    // rgba(0,0,0,0.4)
  }

  .cookie-wrapper {
    background: var(--white);
    // border-radius: var(--borderR-16);
    color: var(--main-color);
    padding: var(--space-16) var(--space-24);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;

    button {
      background: var(--main-color);
      color: var(--white);
      font-weight: var(--font-reg);
      margin-top: var(--space-16);
    }

    button:hover {
      background: var(--playcard-bg);
    }

    .close-button, a {
      background: none;
      font-weight: var(--font-reg);
      color: var(--main-color);
      border: none;
      cursor: pointer;
      margin: 0;
    }

    .close-button:hover {
      background: none;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: var(--fsize-18);
  }

  .content {
    margin-top: var(--space-4);

    p {
      margin-bottom: var(--space-8);
    }

  }



  @media only screen and (min-width: 660px) {
    .cookie-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
