<template>
  <div class="flex-container">
    <div>
      <i class="fas fa-stopwatch icon-marginR-8"></i> <span class="bold">{{ $store.state.roundCounter }}.</span> Runde
    </div>
    <div>
      <i class="fas fa-hourglass-half icon-marginR-8"></i> <span class="bold">{{ gameLenghtDefined }}</span><span v-if="gameLenghtDefined"> Punkte</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gameStats',

    computed: {
      gameLenghtDefined() {
        if(this.$store.state.gameLength[0] != undefined) {
          return this.$store.getters.getGameLength
        } else {
          return 'nicht definiert'
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-container {
    display: flex;
    gap: var(--space-16);
    margin-top: var(--space-12);
    margin-bottom: var(--space-8);
  }

</style>
