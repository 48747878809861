<template>
  <main>
  <h1>Die Zählerapp für Frantic</h1>

  <!--================== running game ==================-->
  <section v-if="runningGame" id="active-game">
    <h2>Laufendes Spiel</h2>
    <game-stats></game-stats>
    <!-- loop through player array -->
    <player-list></player-list>

    <router-link to="/gameview">
      <custom-button text="Weiterspielen"></custom-button>
    </router-link>
    <custom-button text="Neues Spiel starten" btnClass="btn-secondary" @btn-click="resetCompleteGame()"></custom-button>
  </section>

  <!--================== no running game ==================-->
  <section v-else id="inactive-game">
    <p style="margin-bottom: var(--space-8);">Du hast kein laufendes Spiel. Willst du ein neues Spiel starten?</p>
    <router-link to="/settings">
      <custom-button text="Neues Spiel starten" ></custom-button>
    </router-link>
</section>
</main>

<!--================== FAQ ==================-->
<div id="faq">
  <custom-accordion>
   <template v-slot:title>
     <h4>Was bringt mir die App?</h4>
   </template>
   <template v-slot:content>
     <p>
       Die App ist eine Ergänzung und Erleichertung für das Kartenspiel «Frantic», in dem sie das Kopfrechnen für dich übernimmt.
     </p>
   </template>
 </custom-accordion>
  <custom-accordion>
    <template v-slot:title>
      <h4>Wie funktioniert das Ganze?</h4>
    </template>
    <template v-slot:content>
      <p>
        Am Anfang erfasst du alle deine Mitspieler:innen und wählst aus, wie
        lange ihr spielen wollt.
      </p>
      <p>
        Nach jeder Runde werden die Punkte im
        Herzstück der App, dem Calculator, erfasst. Die Punkte musst du dir
        nicht selbst zusammenrechnen, du kannst einfach deine Handkarten
        auswählen und die App kümmert sich um den Rest. Die Punkte und
        Rangliste werden automatisch aktualisiert.
      </p>
    </template>
  </custom-accordion>
  <custom-accordion>
    <template v-slot:title>
      <h4>Kann ich die App auch mit den Erweiterungen nutzen?</h4>
    </template>
    <template v-slot:content>
      <p>
       Nein, aktuell wird nur die Basisversion von Frantic unterstützt.
     </p>
     <p>
       Wenn die App auf Anklang stösst und der Bedarf vorhanden ist, können die Erweiterungen noch hinzugefügt werden.
      </p>
    </template>
  </custom-accordion>
  <custom-accordion>
  <template v-slot:title>
    <h4>Kann ich die App kostenlos nutzen?</h4>
  </template>
  <template v-slot:content>
    <p>
      Ja, die App ist komplett kostenlos nutzbar.
    </p>
  </template>
</custom-accordion>
<custom-accordion>
  <template v-slot:title>
    <h4>Wo werden meine Daten gespeichert?</h4>
  </template>
  <template v-slot:content>
    <p>
       Sämtliche Spieldaten werden nur lokal auf deinem Gerät gespeichert.
     </p>
     <p>
       Die App nutzt jedoch Google Analytics, um das Nutzungserlebnis zu untersuchen und zu verbessern. Mehr Informationen findest du in der  <router-link to="/datenschutz">Datenschutzerklärung</router-link>.
    </p>
  </template>
</custom-accordion>
<custom-accordion>
  <template v-slot:title>
    <h4>Wo kann ich einen Bug oder Featurewünsche melden?</h4>
  </template>
  <template v-slot:content>
    <p>
      Ich freue mich sehr, wenn du mir Bugs, Featurewünsche oder Feedback meldest! Du erreichst mich am besten unter <a href="mailto:robin@ronie.ch">robin@ronie.ch</a>.
    </p>
  </template>
</custom-accordion>
</div>

  <footer class="small footer-start">
    <p>
      A fan project made with ❤️ by <span class="bold">Robin Nieminen</span>
    </p>
    <p>
      Das Kartenspiel Frantic und alle dazugehörigen Logos und grafischen Materialien stehen unter dem Copyright der Entwickler.
      Die Nutzung der Assets wurde von <a href="https://rulefactory.ch/" target="_blank">Rulefactory</a> erlaubt.
    </p>
  </footer>
  <legal-info></legal-info>
</template>

<script>
import customButton from '@/components/ui-elements/customButton.vue'
import gameStats from '@/components/gameStats.vue'
import playerList from '@/components/playerList.vue'
import customAccordion from "@/components/ui-elements/customAccordion";
import legalInfo from "@/components/ui-elements/legalInfo";

export default {
  name: 'StartView',
  components: {
    customButton,
    gameStats,
    playerList,
    customAccordion,
    legalInfo
  },

  methods: {
    resetCompleteGame() {
      if(confirm('Das laufende Spiel wird dabei gelöscht. Dies kann nicht rückgängig gemacht werden.')){
        this.$store.commit('resetGameLength')
        this.$store.commit('resetRoundCounter')
        this.$store.commit('resetPlayerArray')
        this.$store.commit('resetSortedPlayerArray')
        this.$store.commit('setGameInactive')
        // link to settings page
        this.$router.push('/settings')
    }
  }
  },

  computed: {
    runningGame() {
      if(this.$store.getters.playerArLength > 0) {
        return true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  #active-game {
    margin-top: var(--space-24);
  }

  #inactive-game {
    margin-top: var(--space-8)
  }

  #faq {
    margin-top: var(--space-44);
  }

  .content p {
    margin-bottom: var(--space-8);
  }

  .content p:last-child {
    margin-bottom: var(--space-16);
  }

  .footer-start {
    padding: var(--space-24) 0;
    position: sticky;
    background: var(--main-color);
    bottom: 0;
    left: 0;

    p:first-child {
      margin-top: 0;
    }
  }
</style>
