<template>
  <div
    v-for="player, index in $store.state.sortedPlayerArray"
    class="flex-container player-list"
    :class="highlightPlayer && index === 0 ? ['white', 'highlight'] : ''"
    :key="player"
    >
    <p class="semibold">{{ index + 1 }}. {{ player.name }}</p>
    <p>{{ player.score }} Punkte</p> {{ highlightActive() }}
  </div>
</template>

<script>
  export default {
    name: 'playerList',

    props: {
          highlight: {
            type: Boolean,
            default: false
          }
        },

  data() {
    return {
      highlightPlayer: this.highlightPlayer
    }
  },

  methods: {
    highlightActive() {
      console.log(this.highlight);
      if(this.highlight) {
        this.highlightPlayer = true;
      } else {
        this.highlightPlayer = false;
      }
    }
  }
  }
</script>

<style lang="scss" scoped>
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--borderW) solid var(--white);
    padding: var(--space-12) 0;
    gap: var(--space-12);
  }

  .highlight {
    font-size: var(--fsize-18);
    padding: var(--space-16);
  }
</style>
