<template>
  <div>
    <cookie-banner></cookie-banner>
  </div>
  <router-view/>
</template>



<script>
import { useStore } from 'vuex'
import cookieBanner from '@/components/cookieBanner.vue'
   export default {
     components: {
       cookieBanner,
     },

     beforeCreate() {
         // Get our store
         const store = useStore()
         // use store.commit to run any mutation. Below we are running the loadStore mutation
         store.commit('loadStore');
     },

      // computed: {
      //   checkCookies() {
      //     let consent = JSON.parse(localStorage.getItem("cookie-consent"));
      //     console.log(consent);
      //
      //     if(consent) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
   }
</script>
