<template>
   <div class="small">
     <router-link to="/datenschutz">Datenschutzerklärung</router-link>
   </div>
</template>

<script>
  export default {
    name: 'legalInfo'
  }
</script>
